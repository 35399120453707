@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");
/* NAVBAR */
a.navbar-brand img{
    width: 200px !important;
    height: 60px !important;
}
.dropdown-menu{
    background: transparent !important;
    color: #fff !important;
}
.dropdown-item{
    color: #fff !important;
}
li.nav-item a.nav-link{
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 22px;
letter-spacing: 1.5px;
/* text-transform: uppercase; */
}

#collapsibleNavbar ul{
    margin-left: 50px;
}

/* HERO */
.hero{
    margin-top:10% !important;
}
.hero_btns{
    justify-content: space-around;
}
.hero_btns .btn-primary{
    background: #10a8c3 !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    color: rgb(0, 0, 0);
    font-weight: 500;
    height:80px;
    width: 40%;
}
.hero_btns .btn-success{
    background: #262A43 !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    /* color: rgb(0, 0, 0); */
    font-weight: 500;
    height:80px;
    width: 40%;
}

/* SECTION ONE */
.section-one{
    margin-top: 250px;
    width: inherit;
}
.section-one .row .h2{
    font-weight: 600;
    font-size: 40px;
    line-height: 70px;
    letter-spacing: 3.5px;
    /* text-transform: uppercase; */
}

.section-one .row .p{
    
font-style: normal;
font-weight: 200;
font-size: 20px;
line-height: 20px;
margin-top: 50px;
letter-spacing: 1.5px;
/* text-transform: uppercase; */
width:1036px;

}
.section-one .sec_one{
    margin-top: 100px;
    background-image: url(../../assets/img/cardanowhite.png);
    background-repeat:no-repeat;
    background-position: left;
    width: 100%;
}
.section-one .sec_one .sec_h2{
font-style: normal;
font-weight: 550;
font-size: 40px;
line-height: 48px;
letter-spacing: 1.5px;
/* text-transform: uppercase; */
text-align: center;
}
.section-one .sec_one .sec_p{
    margin-top:20px;
    text-align: center;
    color: #fff;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1.5px;
    /* text-transform: uppercase; */
}


.carousel-item .card{
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 511px;
}

.card-body div.h2{
    
font-size: 20px !important;
line-height: 24px !important;
letter-spacing: 1.5px !important;
/* text-transform: uppercase; */
}

.card-header img{
    width: 150px;
    height:160px;
}

.card-body p{
    
font-size: 15px;
line-height: 18px;
text-align: center;
letter-spacing: 1.5px;
/* text-transform: uppercase; */
}
.card-body{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.card-footer .btn-secondary{
    width: 197px;
height: 67px;

background: #03428B;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
}

/* SECTION TWO */
.section_two{
    margin-top: 160px;
}
.section_two .h2{
    /* font-family: 'Roboto'; */
    font-style: normal;
    font-weight: 550;
    font-size: 60px;
    line-height: 60px;
    color: #fff;
    letter-spacing: 2.5px;
    /* text-transform: uppercase; */
}
@media screen and (max-width: 990px) {
    .md-hidden{
        display: none !important;
    }
}
.section_two .sec_p{
    
font-size: 20px;
line-height: 30px;
/* or 150% */

letter-spacing: 2.5px;
/* text-transform: uppercase; */
}
.section_two .sec_btn{
    background: #37328E;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 20px;
height: 50px;
color: #fff;
}

.section_two .text_h2{
    
font-weight: 700;
font-size: 40px;
line-height: 48px;
text-align: center;
letter-spacing: 1.5px;
/* text-transform: uppercase; */
}
.section_two .text_p{
    
font-weight: 400;
font-size: 20px;
line-height: 24px;
text-align: center;
letter-spacing: 1.5px;
/* text-transform: uppercase; */
}

.section_two .h2s{
    
font-weight: 700;
font-size: 20px;
line-height: 24px;
text-align: center;
letter-spacing: 1.5px;
/* text-transform: uppercase; */
}

.section_two .ps{
    margin-top:15px;
font-weight: 400;
font-size: 15px;
line-height: 18px;
letter-spacing: 1.5px;
/* text-transform: uppercase; */
}
.section_two .ps a{
    text-decoration: none;
}
.section_two .flex{
    justify-content: space-between;
}

.section_two .flex .widget_one {
    display: flex;
    flex-direction: column;
    /* justify-content:center; */
    align-items: center;
    background: linear-gradient(177.96deg, rgba(255, 255, 255, 0.17) 6.87%, rgba(214, 214, 214, 0.17) 98.28%);
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 20px;
width:500px;
height: 650px;

}
.section_two .flex .widget_one h2,
.section_two .flex .widget_two h2{
    margin-top: 73px;
    width:253px;
font-weight: 600;
font-size: 40px;
line-height: 45px;
/* or 112% */

letter-spacing: 2.5px;
/* text-transform: uppercase; */
text-align: start;
color: #fff;
}

.section_two .flex .widget_one p,
.section_two .flex .widget_two p{
    margin-top:89px;
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 45px;
/* or 225% */

letter-spacing: 2.5px;
/* text-transform: uppercase; */

}

.section_two .flex .widget_two{
    background: linear-gradient(177.96deg, rgba(111, 200, 209, 0.22) 6.87%, rgba(214, 214, 214, 0.17) 98.28%);
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 20px;
width:500px;
display: flex;
    flex-direction: column;
    /* justify-content:center; */
    align-items: center;
    height: 650px;
}

/* SECTION THREE */
.section_three .h2{
    margin-top: 30px;
font-style: normal;
font-weight: 600;
font-size: 45px;
line-height: 45px;
/* or 90% */

letter-spacing: 2.5px;
/* text-transform: uppercase; */
}

.section_three .p{
    margin-top: 55px;
font-weight: 300;
font-size: 20px;
line-height: 45px;
/* or 225% */

letter-spacing: 2.5px;
/* text-transform: uppercase; */
}

.section_three svg{
    color: #fff;
}

/* FOOTER */
footer.footer{
    background: url(../../assets/svg/background.svg);
    background-repeat: no-repeat;
    background-size:cover;
    height:inherit;
}

.footer h2{
    margin-top:200px;
font-style: normal;
font-weight: 600;
font-size: 50px;
line-height: 24px;
text-align: center;
}

.footer .links{
    width: 880px;
    justify-content:center;
    align-items:center;
    margin-top: 59px;
    margin-bottom: 49px;
}

@media screen and (max-width:600px) {
    .hero_h2{
        line-height: 45px !important;
        letter-spacing: 4.5px !important;
    }
    .column{
        flex-direction: column-reverse !important;
    }
.footer h2{
    margin-top:200px;
font-style: normal;
font-weight: 600;
font-size: 50px;
line-height: 34px !important;
text-align: center;
}
    .footer .links{
        width: 100%;
        margin-top: 49px;
    }
    .footer .links img{
        width: 50px;
    }
}

footer footer.footers{
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
}

.footers .form-group{
    height: 70px;
    background: rgba(111, 200, 209, 0.53);
    border-radius: 50px;
}
.footers .form-group input{
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    height: inherit;
    border: none;
    background: transparent;
    box-shadow: none !important;
}

.footers .form-group button{
    background: rgba(9, 25, 173, 0.45);
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 50px;
width: 30%;
}

footer a{
    text-decoration: none;
    font-weight: 150 !important;
    /* font-family:; */
}
footer a:hover{
    color:#10a8c3 !important;
    
}
.footers h5{
    font-weight: bold !important;
}