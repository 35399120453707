@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");
*{
  scrollbar-width: 5px !important;
  scroll-behavior: smooth !important;
  box-sizing: border-box !important;
}
body {
  margin: 0;
  font-family:  Arial, Helvetica, monospace,'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* background: linear-gradient(177.61deg, #004DA7 41.76%, rgba(53, 99, 104, 0.45) 46.47%, #003F87 54.69%); */
  background: radial-gradient(356.35% 23.74% at 29.38% 52.75%, #00326C 13.64%, rgba(53, 99, 104, 0.45) 26.48%, #00397A 35.83%);
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
}
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
