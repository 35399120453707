#dashboard .btn-primary{
    background: #014FAA !important;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 50px;
}
a{
    text-decoration: none !important;
}
#dashboard .text-primary{
    color: #014FAA !important;
}
#dashboard.primary, .bg-primary{
    background: url(../../assets/img/cardanowhite.png) #014FAA !important;
    background-repeat: no-repeat !important;
    background-size:cover !important;
}
#dashboard #mynavbar .sidebar .nav-item .nav-link{
    background: rgba(1, 79, 170, 0.04);
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 5px;
width: 250px;
margin-top: 10px;
display: flex;
text-decoration: none !important;
justify-content: center;
align-items: center;
}
#dashboard #mynavbar .sidebar .nav-item .nav-link:hover{
    border-left: 4px solid #014FAA !important;
}
#dashboard .top-nav{
    z-index: 9999 !important;
}
#dashboard .active{
    border-left: 4px solid #014FAA !important;
}

#dashboard .sidebar .nav-item img{
    width: 20px !important;
}
#dashboard .farm{
    background-image: url(../../assets/svg/bg.svg);
    background-repeat: no-repeat;
    padding: 3%;
    border-radius:20px;
    /* width: 100%; */
}
#dashboard #mynavbar .sidebar.navbar{
    position: fixed !important;
    top: 0 !important;
    bottom: 0 !important;
    z-index: 1;
}

@media screen and (max-width: 600px){
    #dashboard .sidebar.navbar{
        position: static;
        top: 30;
        left:0;
        width: 1005;
    }
    #dashboard .farm{
        padding: 5%;
        padding-top:10%;
    }
    #dashboard  .sm-hidden{
        display: none !important;
    }
}

/* FARM STYLING */
#dashboard .farmss{
    background: rgba(196, 196, 196, 0.28);
    width: inherit !important;
}

#dashboard .farmss .bg-secondary{
    background: #C4C4C4 !important;
border-radius: 30px;
width: 156px;
}
#dashboard .farmss .form-control{
    background: #C4C4C4 !important;
border-radius: 30px;
}

#dashboard .farmss .farms{
    margin-top: 36px;
    background: #FFFFFF;
box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
border-radius: 20px;
/* width: inherit; */
}

#dashboard .d-flexs{
    position: fixed;
    bottom: 0 !important;
    border-top: 1px solid #014FAA !important;
}

@media screen and (max-width:900px) {
    .navs{
        display: none !important;
    }
}